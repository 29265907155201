import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import './App.css';
import { setAuthToken, setupInterceptors } from './api/authHelper';
import { baseTheme } from './assets/global/Theme-variable';
import { useNavigate } from 'react-router-dom';
import { setCompanySubscription, setEmail, setIsLogin, setIsSubscriptionActive, setIsSubscriptionCancelled, resetUserData } from './redux/slices/userBehaviorSlice';
import { reset } from './redux/slices/dataTableSlice';
import { resetAds } from './redux/slices/adsSlice';
import { setDataTableVisibility } from './redux/slices/dataSetSlice';
import ThemeRoutes from './routes/Router';

function App() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const navigateHomeAndLogout = () => {

    dispatch(setDataTableVisibility(false));
    dispatch(resetUserData());
    dispatch(resetAds());
    dispatch(reset());
    dispatch(setEmail(''));
    dispatch(setIsLogin(false));
    dispatch(setCompanySubscription(false));
    localStorage.clear();
    navigate('/');
  };

  setupInterceptors(navigateHomeAndLogout);
  const setTokenResult = setAuthToken(navigateHomeAndLogout);
  if (setTokenResult.isSuccessLogin) {
    if (setTokenResult.role === 'Company') {
      dispatch(setCompanySubscription(true));
    }
    dispatch(setIsLogin(true));
    dispatch(setEmail(setTokenResult.email));
  }

  dispatch(setIsSubscriptionActive(setTokenResult.isSubscriptionActive));
  dispatch(setIsSubscriptionCancelled(setTokenResult.isSubscriptionCancelled));
  const routing = useRoutes(ThemeRoutes);
  const theme = baseTheme;
  return <ThemeProvider theme={theme}>{routing}</ThemeProvider>;
}

export default App;
