import axios from 'axios';
import { RefreshTokenApiRequest } from './dataWriter';

export const setAuthToken = (navigateHomeAndLogout) => {
  const accessToken = localStorage.getItem('accessToken');

  let email = '';
  let userRole = '';
  let isSuccessLogin = false;
  let isSubscriptionActive = false;
  let isSubscriptionCancelled = false;
  if (accessToken) {
    email = localStorage.getItem('email');
    userRole = localStorage.getItem('userRole');
    isSubscriptionActive = localStorage.getItem('isSubscriptionActive');
    isSubscriptionCancelled = localStorage.getItem('isSubscriptionCancelled');

    // Check if the access token has expired
    const expirationTime = localStorage.getItem('expirationTime');
    if (expirationTime && Date.now() > expirationTime) {
      // If the access token has expired, refresh it
      refreshToken(navigateHomeAndLogout).then((success) => {
        if (success) {
          isSuccessLogin = true;
        }
      });
    } else {
      isSuccessLogin = true;
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
  return { email: email, isSuccessLogin: isSuccessLogin, role: userRole, isSubscriptionActive: isSubscriptionActive, isSubscriptionCancelled: isSubscriptionCancelled };
};

export const refreshToken = async (navigateHomeAndLogout) => {
  // Retrieve the refresh token from local storage
  const refreshToken = localStorage.getItem('refreshToken');

  // Send a POST request to the refresh token URL, including the refresh token in the request body
  return RefreshTokenApiRequest({
    refreshToken: refreshToken
  }).then((response) => {
      // If the request is successful, update the access token and expiration time in local storage
      const { accessToken, expirationTime, refreshToken, isSubscriptionActive, currentSubscriptionPeriodEnd, isSubscriptionCancelled } = response;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('expirationTime', expirationTime);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('isSubscriptionActive', isSubscriptionActive);
      localStorage.setItem('currentSubscriptionPeriodEnd', currentSubscriptionPeriodEnd);
      localStorage.setItem('isSubscriptionCancelled', isSubscriptionCancelled);
      // Update the access token in the axios default headers
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      return true;
    })
    .catch((error) => {
      // If the request fails, remove the refresh token and access token from local storage and the axios default headers
        navigateHomeAndLogout();
      return false;
    });
};

export const setupInterceptors = (navigateHomeAndLogout) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;

      // If the error is a 401 Unauthorized error
      if (error.response.status === 401 && !originalRequest._retry) {
        // Set the _retry flag to prevent infinite loop
        originalRequest._retry = true;

        // Send a refresh token request
        const refreshToken = localStorage.getItem('refreshToken');

        return RefreshTokenApiRequest({
          refreshToken: refreshToken,
        }).then((response) => {
          // Update the access token and expiration time in local storage
          const { token, expirationTime, refreshToken, isSubscriptionActive, currentSubscriptionPeriodEnd, isSubscriptionCancelled } = response;
          localStorage.setItem('accessToken', token);
          localStorage.setItem('expirationTime', expirationTime);
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('isSubscriptionActive', isSubscriptionActive);
          localStorage.setItem('currentSubscriptionPeriodEnd', currentSubscriptionPeriodEnd);
          localStorage.setItem('isSubscriptionCancelled', isSubscriptionCancelled);

          // Update the access token in the original request
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          originalRequest.headers['Authorization'] = `Bearer ${token}`;

          // Resend the original request
          return axios(originalRequest);
        })
        .catch((error) => {
          navigateHomeAndLogout();
          return false;
        });
      }

      return Promise.reject(error);
    },
  );
};
